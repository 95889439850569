import React, { useState } from 'react';
import './contactMe.css';
import imgBg from '../../assets/images/shape-contact.png';
import mail from '../../assets/images/mail.png';
import contactImg from '../../assets/images/contact-2023.png';
import emailjs from 'emailjs-com';
import videoBg from '../../assets/video/4K_5.mp4';

const sendEmail = (e) => {
	e.preventDefault();

	emailjs
		.sendForm(
			'service_cgcstqk',
			'template_mhidqza',
			e.target,
			'user_MfvzCd2fnmehXVcX73FnI',
		)
		.then(
			(result) => {
				console.log(result.text);
			},
			(error) => {
				console.log(error.text);
			},
		);
};

function ContactMe() {
	return (
		<div className="contact-container" id="contact">
			<video
				autoPlay
				loop
				muted
				style={{
					position: 'absolute',
					width: '100%',
					left: '50%',
					top: '50%',
					height: '100%',
					objectFit: 'cover',
					transform: 'translate(-50%, -50%)',
					opacity: '0.3',

					zIndex: '-1',
				}}
			>
				<source src={videoBg} type="video/mp4" />
			</video>
			<div>
				<h1 style={{ textAlign: 'center' }}>
					<img src={mail} alt="Contact Yalda Emami" />
					Contact Me{' '}
				</h1>
			</div>
			<div className="customerForm">
				<div className="contact-form">
					<form
						onSubmit={sendEmail}
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '1px',
						}}
					>
						<input
							type="text"
							aria-required="true"
							placeholder="Name"
							name="name"
						/>

						<input
							type="email"
							aria-required="true"
							placeholder="Email"
							name="user_email"
						/>

						<textarea
							type="text"
							aria-required="true"
							placeholder="Message"
							name="message"
						/>

						<br />
						<button type="submit" value="Send" className="btns primary-btns">
							{' '}
							Send
						</button>
					</form>
				</div>
				<div className="contact-info">
					<p> Phone: + 425-233-2535</p>
					<p> Email: yalda@comcast.net</p>
					<p> Address: Seattle, Washington, USA</p>
					<img src={contactImg} alt="" className="contact-img" />
					<br />
					<br />
				</div>
			</div>
			<div className="copyright">
				© {new Date().getFullYear()}
				<br /> Designed & Developed by Yalda{' '}
			</div>
		</div>
	);
}

export default ContactMe;
