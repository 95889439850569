export const techs = [
	{
		name: 'React',
		iconName: 'react',
	},

	{
		name: 'Javascript',
		iconName: 'javascript',
	},
	{
		name: 'jquery',
		iconName: 'jquery',
	},
	{
		name: 'Sass',
		iconName: 'sass',
	},
	{
		name: 'CSS3',
		iconName: 'css3',
	},
	{
		name: 'HTML 5',
		iconName: 'html5',
	},
	{
		name: 'Bootstrap',
		iconName: 'bootstrap',
	},

	{
		name: 'Nodejs',
		iconName: 'nodejs',
	},

	{
		name: 'npm',
		iconName: 'npm',
	},
	{
		name: 'Mongo DB',
		iconName: 'mongodb',
	},
	{
		name: 'Firebase',
		iconName: 'firebase',
	},
	{
		name: 'Responsive',
		iconName: 'code_badge',
	},

	{
		name: 'Photoshop',
		iconName: 'photoshop',
	},
	{
		name: 'Illustrator',
		iconName: 'illustrator',
	},
];
