import React from 'react';
import './resomeh.css';
import pdf from '../../assets/Yalda-resume.pdf';

import computer from '../../assets/images/res-2023.png';

const scrollToTalk = () => {
	let contactMeScreen = document.getElementById('contact');
	if (!contactMeScreen) return;

	contactMeScreen.scrollIntoView({ behavior: 'smooth' });
};

function AboutMe() {
	return (
		<div className="about__container" id="about-me">
			<div className="comp-img">
				<a href={pdf} target="_blank">
					<img src={computer} alt="about Yalda Emami skills" />
				</a>
			</div>
			<div className="about__text">
				<br />
				<h1>I’m focused on creating meaningful experiences through design</h1>
				<br />
				<p>
					I am a full-stack web and product designer and developer focused on
					helping users/businesses achieve their goals. I do everything from
					Cross-functional Team Leadership to design and create responsive
					websites/products and mobile apps. Highly technical and capable of
					building creative solutions that meet client needs. Excellent
					communicator with the ability to effectively present complex
					information at all levels of an organization.
				</p>
				<button
					onClick={scrollToTalk}
					className="btns primary-btns centeredMust"
				>
					Let's Talk
				</button>
			</div>
		</div>
	);
}

export default AboutMe;
