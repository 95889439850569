export const navigationLinks = [
	{
		name: 'Home',
		ref: '#home',
	},
	{
		name: 'About',
		ref: '#about-me',
	},
	{
		name: 'Works',
		ref: '#works',
	},
	{
		name: 'Skill',
		ref: '#skill',
	},

	{
		name: 'Contact',
		ref: '#contact',
	},
];
