import React from 'react';
import './technologies.css';
import DevIcon from 'devicon-react-svg';
import { techs } from '../../helpers/techs';
import gerdi from '../../assets/images/logo-sm.png';

function Technologies() {
	return (
		<>
			<div className="technologies__container" id="skill">
				<div>
					<h1 style={{ textAlign: 'center' }}>
						Some of the techonologies that I use
					</h1>
				</div>
				<div className="tech__cards__container">
					{techs.map((e, idx) => {
						return (
							<div key={idx} className="tech__card">
								<DevIcon className="tech__icons" icon={e.iconName} />
								<span style={{ margin: '5px', fontSize: '1.5rem' }}>
									{e.name}
								</span>
							</div>
						);
					})}
				</div>
			</div>
			<section class="skills skill-bg section-2">
				<div class="skills__box">
					<div class="skills__box--icon">
						<img src={gerdi} alt="" />
					</div>
					<div class="skills__box--title"> Front end developer </div>
					<div class="skills__box--content">
						<p>
							Implementing visual elements that users see and interact with
							applications.
							<br />
							- HTML5 / CSS3 / SASS
							<br />
							- React & Java script
							<br />
							- Css Grid Layout
							<br />
							- npm & Node.js
							<br />
							- MERN Stack
							<br />
							- Design Tools
							<br />- Software Frameworks
						</p>
					</div>
				</div>
				<div class="skills__box">
					<div class="skills__box--icon">
						<img src={gerdi} alt="" />
					</div>
					<div class="skills__box--title">
						{' '}
						Information architecture (IA & UX){' '}
					</div>
					<div class="skills__box--content">
						<p>
							Draw upon user data, research and team input to generate
							interaction concepts that enable seamless and relevant experiences
							<br />
							<br />
							- Goal Driven Design
							<br />
							- Usability <br />
							- User Experience <br />
							- Utility
							<br />
							- Affordance and Signifier
							<br />
						</p>
					</div>
				</div>
				<div class="skills__box">
					<div class="skills__box--icon">
						<img src={gerdi} alt="" />
					</div>
					<div class="skills__box--title"> User-centered design (UCD)</div>
					<div class="skills__box--content">
						<p>
							Focus on the users and their needs in each phase of the design
							process & brainstorm solutions to current pain points. <br />
							<br />
							- Wireframe <br />
							- Visual Design
							<br />
							- User Experience
							<br />
							- Art Direction
							<br />
							- UsabilityTools
							<br />
						</p>
					</div>
				</div>
			</section>
		</>
	);
}

export default Technologies;
