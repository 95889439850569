import React from 'react';
import './Carousel.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img3D from '../../assets/work-2022/3d.png';
import think from '../../assets/work-2022/think.png';
import web from '../../assets/work-2022/web.png';
import japan from '../../assets/work-2022/japan.png';
import girls from '../../assets/work-2022/girls.png';
import cottage from '../../assets/work-2022/cottage.png';
import maverick from '../../assets/work-2022/maverick.png';
import resume from '../../assets/work-2022/resume.png';
import play from '../../assets/work-2022/play.png';
import design from '../../assets/work-2022/design.png';
import photoshop from '../../assets/work-2022/photoshop.png';
import mobile from '../../assets/work-2022/mobile-3d.png';

import lav from '../../assets/work-2022/lav.png';

function CarouselResponse() {
	return (
		<div className="carousel-container" id="works">
			<h1 style={{ textAlign: 'center' }}>Some of my work in Year 2022</h1>
			<div className="carousel2022">
				<Carousel autoPlay interval="4000" transitionTime="3000" infiniteLoop>
					<div>
						<img src={img3D} />
					</div>

					<div>
						<img src={think} />
					</div>

					<div>
						<img src={web} />
					</div>
					<div>
						<img src={japan} />
					</div>
					<div>
						<img src={girls} />
					</div>
					<div>
						<img src={cottage} />
					</div>

					<div>
						<img src={maverick} />
					</div>
					<div>
						<img src={resume} />
					</div>

					<div>
						<img src={play} />
					</div>
					<div>
						<img src={design} />
					</div>
					<div>
						<img src={photoshop} />
					</div>
					<div>
						<img src={mobile} />
					</div>

					<div>
						<img src={lav} />
					</div>
				</Carousel>
			</div>
		</div>
	);
}

export default CarouselResponse;
