import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './screens/navigationBar/NavigationBar';
import Home from './screens/Home/Home';

import Resomeh from './screens/resume/Resomeh';
import Technologies from './screens/technologies/Technologies';
import CarouselResponse from './screens/carousel/Carousel';
import ContactMe from './screens/contactMe/ContactMe';
import Footer from './screens/Footer/Footer';

function App() {
	return (
		<div className="App">
			<Navigation />
			<Home />
			<Resomeh />
			<CarouselResponse />
			<Technologies />
			<ContactMe />
		</div>
	);
}

export default App;
