import React from 'react';
import Typical from 'react-typical';
import './Profile.css';
import pdf from '../../../assets/Yalda-resume.pdf';

export default function Profile() {
	return (
		<div className="profile-container">
			<div className="profile-parent">
				<div className="profile-details">
					<div className="profile-details-name">
						<span className="primary-text">
							{' '}
							I'm <span className="highlighted-text">Yalda</span>
						</span>
					</div>
					<div className="profile-details-role">
						<span className="primary-text">
							{' '}
							<h1>
								<Typical
									loop={Infinity}
									steps={['Full stack Developer ', 5000]}
								/>
							</h1>
						</span>
						<span className="profile-role-tagline">
							I Design & Code User Friendly Apps / Products & Websites.
						</span>
					</div>

					<div className="profile-options">
						<a href={pdf} target="_blank">
							<button className="btns primary-btns"> My Resume </button>
						</a>
					</div>
				</div>
				<div className="profile-picture">
					<div className="profile-picture-background "></div>
				</div>
			</div>
		</div>
	);
}
